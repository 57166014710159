<template>
  <v-autocomplete
    :error-messages="errorMessages"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="returnObject"
    :no-data-text="$t('common.noData')"
    :disabled="disabled"
    v-model="vModelToShow"
    @change="changeEvent"
  >
    <template #label> {{ label }}<RedAsterisk v-if="required" /> </template>
  </v-autocomplete>
</template>
<script>
import RedAsterisk from "@/flit2go-web-core/components/fields/f2g-red-asterisk";
export default {
  name: "F2gAutocomplete",
  components: {
    RedAsterisk,
  },
  props: {
    disabled: { type: Boolean, required: false, default: false },
    errorMessages: { type: Array, required: false },
    items: { type: Array, required: true },
    itemText: { type: String, required: false, default: "" },
    itemValue: { type: String, required: false, default: "" },
    returnObject: { type: Boolean, required: false, default: false },
    value: { type: [String, Object], required: false },
    label: { type: String, required: false, default: "" },
    required: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    vModelToShow: {},
  }),
  mounted() {
    this.vModelToShow = this.value;
  },
  watch: {
    value() {
      this.vModelToShow = this.value;
    },
  },
  methods: {
    touchField() {
      this.$emit("touch");
    },
    changeEvent() {
      this.$emit('input', this.vModelToShow)
      this.$emit('change')
    },
  },
};
</script>
